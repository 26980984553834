<template>
  <main class="relative bg-[#003d31] w-full">
    <div class="overlay absolute top-0 left-0 w-full h-full"></div>
    <slot class="absolute top-0 left-0 w-full"></slot>
  </main>
</template>

<script>
export default {
  name: "MWBackground",
};
</script>

<style>
.overlay {
  background-image: url("../../assets/images/miningWeek/diagonalstripe-black.png");
  opacity: 0.6;
}
</style>
