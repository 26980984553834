<template>
  <div
    class="flex flex-col items-center bg-[#003d31] text-sm text-white text-nowrap"
  >
    <div
      class="flex max-md:flex-col w-full min-[1200px]:w-4/5 max-[1199px]:px-5 py-5 max-md:gap-5"
    >
      <div class="w-3/4">
        <h6 class="uppercase mb-3">Contact</h6>
        <ul
          class="flex max-md:flex-col justify-between gap-5 md:gap-10 mr-20 space-y-1"
        >
          <li class="flex flex-col gap-1">
            <a
              v-for="phone in phoneList"
              :key="phone.id"
              :href="`tel:${phone.information}`"
              class="flex items-center gap-1"
            >
              <font-awesome-icon icon="fa-solid fa-headset" />
              {{ phone.information }}
            </a>
          </li>

          <li class="flex flex-col gap-1">
            <a
              v-for="email in emailList"
              :key="email.id"
              :href="`mailto:${email.information}`"
              class="flex items-center gap-1"
            >
              <font-awesome-icon icon="fa-solid fa-envelope" />
              {{ email.information }}
            </a>
          </li>

          <li class="flex flex-col gap-1">
            <a
              v-for="link in linkList"
              :key="link.id"
              :href="`https://${link.information}`"
              target="_blank"
              class="flex items-center gap-1"
            >
              <font-awesome-icon icon="fa-solid fa-globe" />
              {{ link.information }}
            </a>
          </li>
        </ul>
      </div>

      <div>
        <h6 class="uppercase mb-3">Social media</h6>
        <div class="flex items-center gap-2">
          <a
            v-for="link in socialLinks"
            :key="link.id"
            :href="link.link"
            target="_blank"
            class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#00a375]"
          >
            <font-awesome-icon :icon="link.icon" />
          </a>
        </div>
      </div>
    </div>

    <div
      class="w-full min-[1200px]:w-4/5 border-t border-[#00a375] text-center py-5"
    >
      <a href="https://bowsys.mn" target="_blank" class="hover:underline"
        >Copyright by &copy; BOW LLC - 2024</a
      >
    </div>
  </div>
</template>

<script>
import clientMWInstance from "@/lib/clientMWInstance";
import { onMounted, ref, computed } from "vue";

export default {
  name: "MWFooter",
  setup() {
    const footerInfo = ref([]);
    const socialLinks = ref([]);

    const fetchFooterInformation = async () => {
      try {
        const res = await clientMWInstance.get("/get/footer");
        if (res.status === 200) {
          footerInfo.value = res.data.footer;
        }
        const linkRes = await clientMWInstance.get("/get/social-link");
        if (linkRes.status === 200) {
          socialLinks.value = linkRes.data.link;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      await fetchFooterInformation();
    });

    const phoneList = computed(() => {
      return footerInfo.value.filter((item) => item.type === "phone");
    });

    const emailList = computed(() => {
      return footerInfo.value.filter((item) => item.type === "email");
    });
    const linkList = computed(() => {
      return footerInfo.value.filter((item) => item.type === "link");
    });

    return { phoneList, emailList, linkList, socialLinks };
  },
};
</script>
