<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">Хүсэлт</h1>

    <!-- list -->
    <div v-if="!isLoading" class="bg-white rounded shadow">
      <h6
        class="text-base font-bold border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        Гишүүнээр элсэх хүсэлт
      </h6>

      <div v-if="requests.length > 0" class="p-3 space-y-5 text-xs">
        <div v-if="isSearchingMode" class="flex items-center gap-5">
          <h6>Хайх:</h6>
          <select name="" id="" class="p-1 border rounded">
            <option value="" disabled>Хайлтын төрөл</option>
            <option value="">Утасны дугаар</option>
            <option value="">И-мэйл хаяг</option>
            <option value="">Он сараар</option>
          </select>
        </div>
        <div class="flex justify-between items-center">
          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="dataPerPage"
              id="dataPerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>

          <span>Нийт хүсэлтийн тоо: {{ totalRequestCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <hot-table :settings="hotSettings" :data="requests"></hot-table>
      </div>

      <div
        v-else-if="requests.length === 0"
        class="flex justify-center items-center h-[50vh]"
      >
        Хүсэлт хоосон байна
      </div>
    </div>

    <!-- loading -->
    <div
      v-else-if="isLoading"
      class="flex justify-center items-center h-[50vh]"
    >
      <spin-loading />
    </div>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, watch } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MMOfficeRequest",
  components: { AdminNavbar, SpinLoading, PaginationCart, HotTable },
  setup() {
    const isLoading = ref(false);
    const requests = ref([]);
    const dataPerPage = ref(10);
    const totalRequestCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchRequestData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/get/office-request?page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        requests.value = res.data.request;
        totalPages.value = res.data.totalPages;
        totalRequestCount.value = res.data.total;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchRequestData();
    });

    watch([() => dataPerPage.value], () => {
      currentPage.value = 1;
      fetchRequestData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchRequestData();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center items-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Хүсэлт гаргасан зорилго",
        "Хүсэлт гаргасан огноо",
        "Овог нэр",
        "И-мэйл хаяг",
        "Утасны дугаар",
        "Бизнесийн төрөл",
      ],
      columns: [
        {
          data: "",
          readOnly: true,
          renderer: function (instance, td, row) {
            td.classList.add("htMiddle");
            let purpose = "";
            const requestData = requests.value[row];
            if (requestData.member_request) purpose = "Гишүүнээр элсэх";
            else if (requestData.codex_request) purpose = "ХУУ кодекс нэгдэх";
            else if (requestData.meeting_request)
              purpose = "Уулзалтын цаг товлох";
            td.innerHTML = `<span class="flex justify-center items-center p-2 text-black">${purpose}</span>`;
            return td;
          },
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "email",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "phone_number",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "business_type",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const isSearchingMode = ref(false);
    const toggleSearchMode = () => {
      isSearchingMode.value = !isSearchingMode.value;
    };

    return {
      isLoading,
      requests,
      dataPerPage,
      totalRequestCount,
      totalPages,
      currentPage,
      hotSettings,
      onPageChanged,
      isSearchingMode,
      toggleSearchMode,
    };
  },
};
</script>
