<template>
  <!-- large screen -->
  <header
    class="flex justify-center items-center bg-[#003d31] sticky top-0 z-10"
  >
    <div
      class="flex justify-between items-center w-full min-[1200px]:w-4/5 border-b-2 border-white text-sm px-5"
    >
      <img
        src="../../assets/images/miningWeek/mining-week-logo.png"
        alt="logo"
        class="w-[8rem] h-[2rem] object-fit my-3"
      />
      <button
        @click="toggleDrawer()"
        class="block lg:hidden border border-white rounded px-2 py-1"
      >
        <font-awesome-icon icon="fa-solid fa-bars" class="text-xl text-white" />
      </button>

      <nav class="hidden lg:block">
        <ul class="flex justify-between gap-8 text-white">
          <li
            v-for="(link, index) in categories"
            :key="index"
            class="flex justify-center items-center border-b-4 hover:border-[#00a375]"
            :class="
              getNavigationPath(link) === isRoute
                ? 'border-[#00a375]'
                : 'border-transparent'
            "
          >
            <router-link :to="getNavigationPath(link)">
              {{ lang === "mn" ? link.category : link.category_en }}
            </router-link>
          </li>
          <li class="flex justify-center items-center gap-1 ml-3">
            <button
              @click="toggleLanguage('en')"
              class="flex justify-center items-center h-[3.5rem] border-b-4 border-transparent"
              :class="
                lang === 'en' ? 'text-[#00a375]' : 'hover:border-[#00a375]'
              "
            >
              EN
            </button>
            /
            <button
              @click="toggleLanguage('mn')"
              class="flex justify-center items-center h-[3.5rem] border-b-4 border-transparent"
              :class="
                lang === 'mn' ? 'text-[#00a375]' : 'hover:border-[#00a375]'
              "
            >
              MN
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <!-- small screen -->
  <Transition name="fade" class="min-[1200px]:hidden">
    <div
      v-if="isDrawerOpen"
      class="fixed top-0 left-0 z-20 h-screen w-[18rem] bg-[#003d31] text-white text-sm p-5"
    >
      <button @click="toggleDrawer()" class="absolute top-3 right-3">
        <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
      </button>

      <ul class="mt-10">
        <li
          v-for="(link, index) in categories"
          :key="index"
          class="border-b border-slate-200 py-3"
          :class="
            getNavigationPath(link) === isRoute
              ? 'text-[#00a375]'
              : 'text-white'
          "
        >
          <router-link :to="getNavigationPath(link)">
            {{ lang === "mn" ? link.category : link.category_en }}
          </router-link>
        </li>
        <li class="flex gap-2 border-b border-slate-200 py-3">
          <button
            @click="toggleLanguage('en')"
            :class="lang === 'en' ? 'text-[#00a375]' : 'hover:border-[#00a375]'"
          >
            EN
          </button>
          /
          <button
            @click="toggleLanguage('mn')"
            :class="lang === 'mn' ? 'text-[#00a375]' : 'hover:border-[#00a375]'"
          >
            MN
          </button>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
import clientMWInstance from "@/lib/clientMWInstance";
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MWNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const toggleLanguage = (value) => {
      store.dispatch("toggleLanguage", value);
    };

    const categories = ref([]);
    const fetchAllCategories = async () => {
      try {
        const res = await clientMWInstance.get("/get/category");
        if (res.status === 200) {
          categories.value = res.data.category;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      await fetchAllCategories();
    });

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    const route = useRoute();
    const isRoute = computed(() => route.path);

    const getNavigationPath = (cat) => {
      if (!cat || !cat.cid) {
        console.error("Invalid category object:", cat);
        return "";
      }

      if (cat.cid === 1) {
        return "/mw";
      } else if (cat.cid === 4) {
        return "/mw/presentation";
      } else if (cat.page_type === "static") {
        return `/mw/category/${cat.cid}`;
      } else {
        return `/mw/news/${cat.cid}`;
      }
    };

    return {
      categories,
      lang,
      toggleLanguage,
      isDrawerOpen,
      toggleDrawer,
      isRoute,
      getNavigationPath,
    };
  },
};
</script>
