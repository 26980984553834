<template>
  <MWBackground>
    <div
      class="relative min-h-[80vh] flex flex-col justify-center items-center gap-3 text-white"
    >
      <font-awesome-icon
        icon="fa-solid fa-screwdriver-wrench"
        class="text-8xl"
      />
      <p>Уучлаарай, хуудас түр засвартай байна.</p>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "../../components/MiningWeek/MWBackground";

export default {
  name: "MWPresentation",
  components: {
    MWBackground,
  },
  setup() {
    return {};
  },
};
</script>
