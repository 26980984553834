<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">"Mining Week" арга хэмжээний бүртгэл</h1>

    <!-- registeredUsers List -->

    <div class="bg-white rounded shadow">
      <h6 class="text-base border-t-4 border-t-blue-500 rounded-t border-b p-3">
        Бүртгэлийн жагсаалт
      </h6>

      <div v-if="!isLoading">
        <div class="p-3 space-y-5">
          <!-- FILTER -->
          <div class="flex items-center bg-gray-200 w-full rounded text-xs p-2">
            <label for="selectedSearchCat" class="mr-2">Хайлтын хэлбэр:</label>
            <select
              name="selectedSearchCat"
              id="selectedSearchCat"
              class="rounded p-1 mr-5 w-[10rem]"
              :class="
                !selectedSearchCat && isFiltered
                  ? 'border border-red-500'
                  : 'border'
              "
              v-model="selectedSearchCat"
            >
              <option value="phone_number">Утасны дугаараар</option>
              <option value="email">И-мэйл хаягаар</option>
              <option value="first_name">Нэрээр</option>
              <option value="company">Байгууллагаар</option>
            </select>

            <label for="searchTerm" class="mr-2">Хайлтын өгөгдөл:</label>
            <input
              id="searchTerm"
              name="searchTerm"
              type="text"
              class="rounded p-1 mr-5 w-[20rem]"
              :class="
                !searchTerm && isFiltered ? 'border border-red-500' : 'border'
              "
              v-model="searchTerm"
            />

            <button
              @click="filterData()"
              class="bg-blue-500 p-1 rounded text-white mr-2"
            >
              <font-awesome-icon icon="fa-solid fa-filter" class="mr-1" />Шүүх
            </button>
            <button
              @click="resetFilter()"
              class="bg-amber-500 p-1 rounded text-white"
            >
              <font-awesome-icon
                icon="fa-solid fa-eraser"
                class="mr-1"
              />Арилгах
            </button>
          </div>

          <!-- PAGINATION -->
          <div class="flex justify-between items-center text-xs">
            <div class="space-x-3">
              <span>Арга хэмжээ:</span>
              <select
                name="selectedEvent"
                id="selectedEvent"
                class="border rounded p-1 text-blue-500 max-w-[20rem]"
                v-model="selectedEvent"
              >
                <option
                  v-for="cat in events"
                  :key="cat.id"
                  :value="cat.id"
                  class="text-pretty"
                >
                  {{ cat.event }}
                </option>
              </select>
            </div>

            <div class="space-x-3">
              <span>Хуудас бүрт:</span>
              <select
                name="pagePerPage"
                id="pagePerPage"
                class="border rounded p-1 text-blue-500"
                v-model="dataPerPage"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </select>
            </div>

            <span>Нийт бүртгэлийн тоо: {{ totalRegisterCount }} </span>

            <div class="flex items-center gap-3">
              <span>Нийт хуудас: {{ totalPages }} </span>
              <PaginationCart
                :totalPages="totalPages"
                :modelValue="currentPage"
                :style="{
                  color: 'blue-500',
                  height: 7,
                  width: 7,
                  fontSize: 'xs',
                }"
                @page-changed="onPageChanged"
              />
            </div>
          </div>

          <div v-if="registeredUsers.length > 0">
            <hot-table
              :settings="hotSettings"
              :data="registeredUsers"
            ></hot-table>
          </div>

          <div
            v-else-if="registeredUsers.length === 0"
            class="flex justify-center items-center h-[60vh]"
          >
            Жагсаалт хоосон байна
          </div>
        </div>
      </div>

      <div v-else class="flex justify-center items-center h-[70vh]">
        <SpinLoading :color="'rgb(59 130 246)'" />
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import { onMounted, ref, watch } from "vue";
import adminMWInstance from "@/lib/adminMWInstance";

registerAllModules();

export default {
  name: "MWRegistration",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    HotTable,
  },
  setup() {
    const editingMode = ref(false);
    const isLoading = ref(false);
    const events = ref([]);
    const registeredUsers = ref([]);
    const selectedEvent = ref(3);

    const dataPerPage = ref(50);
    const totalRegisterCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchEvents = async () => {
      try {
        const res = await adminMWInstance.get("/get/event");
        if (res.status === 200) {
          events.value = res.data.event;
        }
      } catch (err) {
        return err;
      }
    };

    const fetchRegistration = async () => {
      try {
        isLoading.value = true;

        let res;
        if (selectedSearchCat.value && searchTerm.value) {
          res = await adminMWInstance.get(
            `/get/user-registration?event=${selectedEvent.value}&page=${currentPage.value}&limit=${dataPerPage.value}&searchCat=${selectedSearchCat.value}&searchTerm=${searchTerm.value}`
          );
        } else {
          res = await adminMWInstance.get(
            `/get/user-registration?event=${selectedEvent.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
          );
        }

        if (res.status === 200) {
          registeredUsers.value = res.data.register;
          totalRegisterCount.value = res.data.total;
          totalPages.value = res.data.totalPages;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchEvents();
      await fetchRegistration();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchRegistration();
    };

    watch([() => dataPerPage.value, () => selectedEvent.value], () => {
      currentPage.value = 1;
      fetchRegistration();
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Нэр",
        "Овог",
        "Байгууллага",
        "Улс",
        "И-мэйл хаяг",
        "Утасны дугаар",
        "Бүртгүүлсэн огноо",
      ],
      columns: [
        {
          data: "first_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "last_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "company",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "country",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "email",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "phone_number",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    //FILTER
    const selectedSearchCat = ref(null);
    const searchTerm = ref(null);
    const isFiltered = ref(false);

    const validateFilter = () => {
      return selectedSearchCat.value && searchTerm.value;
    };

    const filterData = async () => {
      isFiltered.value = true;
      if (!validateFilter()) {
        return;
      }
      await fetchRegistration();
      isFiltered.value = false;
    };

    const resetFilter = async () => {
      selectedSearchCat.value = null;
      searchTerm.value = null;

      await fetchRegistration();
    };

    return {
      editingMode,
      isLoading,
      registeredUsers,
      dataPerPage,
      totalRegisterCount,
      totalPages,
      currentPage,
      onPageChanged,
      selectedEvent,
      events,
      hotSettings,
      selectedSearchCat,
      searchTerm,
      filterData,
      isFiltered,
      resetFilter,
    };
  },
};
</script>
