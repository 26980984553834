<template>
  <MWBackground>
    <div v-if="isRoute === '2'" class="min-h-[80vh] flex justify-center py-10">
      <MWForm class="text-sm text-white" />
    </div>

    <div
      v-else
      class="relative min-h-[80vh] flex flex-col justify-center items-center gap-3 text-white"
    >
      <font-awesome-icon
        icon="fa-solid fa-screwdriver-wrench"
        class="text-8xl"
      />
      <p>Уучлаарай, хуудас түр засвартай байна.</p>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "../../components/MiningWeek/MWBackground";
import MWForm from "./MWForm.vue";
import { watch, ref, onMounted } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "MWStaticPage",
  components: {
    MWBackground,
    MWForm,
  },
  setup() {
    const route = useRoute();
    const isRoute = ref(null);

    onMounted(() => {
      isRoute.value = route.params.cid;
    });

    watch(
      () => route.params.cid,
      (newCid) => {
        isRoute.value = newCid;
      }
    );

    return { isRoute };
  },
};
</script>
