<template>
  <div class="flex justify-center">
    <header
      class="flex justify-center min-[1201px]:justify-between items-center w-full min-[1200px]:w-4/5 text-green py-3"
    >
      <button @click="toggleLanguage()" class="text-sm max-[1200px]:hidden">
        <font-awesome-icon icon="fa-solid fa-globe" class="mr-1" />{{
          lang === "mn" ? "Монгол" : "English"
        }}
      </button>
      <router-link to="/mm">
        <img
          src="../../assets/images/miningMongolia/header_logo.png"
          alt="logo"
          class="w-[15rem] min-[1200px]:w-[19rem] object-fit"
        />
      </router-link>
      <div class="flex items-center gap-5 max-[1200px]:hidden">
        <a
          v-for="link in socialLinks"
          :key="link.id"
          :href="link.link"
          target="_blank"
        >
          <font-awesome-icon :icon="link.icon" />
        </a>
      </div>
    </header>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "MMContactNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const socialLinks = computed(() => store.getters["client/getSocialLinks"]);

    const toggleLanguage = () => {
      store.dispatch("toggleLanguage");
    };

    onMounted(async () => {
      try {
        await store.dispatch("client/fetchSocialLinks");
      } catch (err) {
        return err;
      }
    });

    return {
      lang,
      toggleLanguage,
      socialLinks,
    };
  },
};
</script>
