<template>
  <div>
    <MMContactNavbar v-if="isPage1" />
    <MMMSEData v-if="isPage1" />
    <MMNavbar v-if="isPage1" />
    <MWNavbar v-if="isPage2" />
    <RouterView />
    <MMFooter v-if="isPage1" />
    <MWFooter v-if="isPage2" />
    <QuickContact
      v-if="isPage1 || isPage2"
      :backgroundColor="isPage1 ? 'bg-green' : 'bg-[#00a375]'"
    />
    <BackToTheTopButton
      v-if="isPage1 || isPage2"
      :backgroundColor="isPage1 ? 'bg-green' : 'bg-[#00a375]'"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
// mining mongolia
import MMMSEData from "./components/MiningMongolia/MMMSEData.vue";
import MMContactNavbar from "./components/MiningMongolia/MMContactNavbar.vue";
import MMNavbar from "./components/MiningMongolia/MMNavbar.vue";
import MMFooter from "./components/MiningMongolia/MMFooter.vue";
import QuickContact from "./components/ui/QuickContact.vue";
import BackToTheTopButton from "./components/ui/BackToTheTopButton.vue";
// mining week
import MWNavbar from "./components/MiningWeek/MWNavbar.vue";
import MWFooter from "./components/MiningWeek/MWFooter.vue";

export default {
  name: "App",
  components: {
    MMMSEData,
    MMContactNavbar,
    MMNavbar,
    MMFooter,
    QuickContact,
    BackToTheTopButton,
    MWNavbar,
    MWFooter,
  },
  setup() {
    const route = useRoute();

    const isPage1 = computed(() => route.path.includes("/mm"));
    const isPage2 = computed(() => route.path.includes("/mw"));

    onMounted(() => {
      const domain = window.location.hostname;
      const body = document.body;

      if (domain.includes("miningmongolia.mn")) {
        body.classList.add("miningmongolia-scrollbar");
      } else if (domain.includes("miningweek.mn")) {
        body.classList.add("miningweek-scrollbar");
      } else if (domain.includes("localhost")) {
        body.classList.add("miningmongolia-scrollbar");
      }
    });

    return {
      isPage1,
      isPage2,
    };
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(226 232 240);
}

.miningmongolia-scrollbar::-webkit-scrollbar-thumb {
  background: #537d23 !important;
}

.miningweek-scrollbar::-webkit-scrollbar-thumb {
  background: #003d31 !important;
}
</style>
